import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import A1 from '../Images/logo-w.png';


const RegisterForm = () => {
  // State to manage form inputs
  const [userNm, setUserNm] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [emailId, setEmailId] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [itemMst, setItemMst] = useState(false);
  const [suppMst, setSuppMst] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    const formData = {
      User_nm: userNm,
      Phone_no: phoneNo,
      email_id: emailId,
      password: password,
      item_mst: itemMst,
      supp_mst: suppMst
    };

    try {
      const response = await fetch('https://erpapi.emedha.in/auth/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const result = await response.json();

      if (response.ok) {
        setSuccess(result.message); 
        setError('');
        setUserNm('');
        setPhoneNo('');
        setEmailId('');
        setPassword('');
        setConfirmPassword('');
        setItemMst(false);
        setSuppMst(false);
      } else {
        setError(result.message); 
        setSuccess('');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('There was an error with your registration');
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center min-vh-100">
      <form className="form text-white p-4 rounded shadow-lg" style={{ maxWidth: '400px', width: '100%' , backgroundColor:'#dbc4a9' }} onSubmit={handleSubmit}>

      <img src={A1} alt="item" style={{ width: "60%", textAlign:'center' }} />

        <h2 className="title text-primary text-center mt-3 mb-4">REGISTER</h2>
        <p className="text-center mb-3 text-black">
          Signup now and get full access to our app.
        </p>

        {/* Name and Mobile inputs */}
        <div className="d-flex gap-2 mb-3">
          <div className="flex-fill">
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              value={userNm}
              onChange={(e) => setUserNm(e.target.value)}
              required
            />
          </div>
          <div className="flex-fill">
            <input
              type="number"
              className="form-control"
              placeholder="Mobile No"
              value={phoneNo}
              onChange={(e) => setPhoneNo(e.target.value)}
              required
            />
          </div>
        </div>

        <input
          type="email"
          className="form-control mb-3"
          placeholder="Email"
          value={emailId}
          onChange={(e) => setEmailId(e.target.value)}
          required
        />
        <input
          type="password"
          className="form-control mb-3"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="password"
          className="form-control mb-3"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />

        <div className="row mb-3">
          <div className="col-6">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="itemMaster"
                checked={itemMst}
                onChange={() => setItemMst(!itemMst)}
              />
              <label className="form-check-label text-dark" htmlFor="itemMaster">
                Item Master
              </label>
            </div>
          </div>
          <div className="col-6">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="supplierMaster"
                checked={suppMst}
                onChange={() => setSuppMst(!suppMst)}
              />
              <label className="form-check-label text-dark" htmlFor="supplierMaster">
                Supplier Master
              </label>
            </div>
          </div>
        </div>

        <button type="submit" className="btn btn-primary w-100 py-2 mb-3">Submit</button>

        {error && <div className="text-danger text-center">{error}</div>}
        {success && <div className="text-success text-center">{success}</div>}

        <p className="text-center text-dark" style={{ fontSize: '13.5px' }}>
          Already have an account? <Link to="/" style={{ color: 'darkblue' }}>Login In</Link>
        </p>
      </form>
    </div>
  );
};

export default RegisterForm;
 