
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaSave, FaSearch } from 'react-icons/fa';
import { FaCheck, FaPlus } from 'react-icons/fa6';
import { HiOutlineRefresh } from 'react-icons/hi';
import { IoPrint } from 'react-icons/io5';
import { LuView } from 'react-icons/lu';
import { MdEdit } from 'react-icons/md';
import { RiCloseLargeFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import A1 from '../Images/logo-w.png';


const Indent = () => {
    const [items, setItems] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ Location: '', indentNo: '', indentDt: '', indentReqBy: '', department: '', itemCd: '', reqdt: '', suppid: '', qty: '', remarks: '', approvedqty: '', approvedby: '', close: '' });
    const [showForm, setShowForm] = useState(false);


    const user = JSON.parse(localStorage.getItem('user'));
    const user_Id = user ? user.id : null;

    useEffect(() => {
        fetchItems();
        fetchSuppliers();
        fetchItemssss();
    }, []);

    const fetchItems = async () => {
        try {
            const response = await fetch('https://erpapi.emedha.in/pages/api/api/itemsmasterform');
            if (!response.ok) {
                throw new Error('Failed to fetch items');
            }
            const data = await response.json();
            console.log(data)
            setItems(data);
            console.log('Fetched items:', data);
        } catch (err) {
            console.error('Error fetching items:', err.message);
        }
    };

    const [rows, setRows] = useState([...Array(20)].map(() => ({
        itemCd: '',
        itemDesc: '',
        itemUOM: '',
        reqdt: '',
        supplierName: '',
        qty: '',
        remarks: '',
        approvedqty: '',
        approvedby: '',
        close: ''
    })));


    const [selectedItem, setSelectedItem] = useState({
        itemCd: '',
        itemDesc: '',
        itemUOM: '',
    });
    const handleItemSelects = (itemCd, index) => {
        const selectedItem = items.find(item => item.itemCd === itemCd);
        if (selectedItem) {
            const updatedRows = [...rows];
            updatedRows[index] = {
                itemCd: selectedItem.itemCd,
                itemDesc: selectedItem.itemDesc,
                itemUOM: selectedItem.itemUOM,
            };
            setRows(updatedRows);
        }
    };

    const fetchSuppliers = async () => {
        try {
            const response = await fetch('https://erpapi.emedha.in/pages/api/api/suppliers');
            if (!response.ok) {
                throw new Error('Failed to fetch suppliers');
            }
            const data = await response.json();
            setSuppliers(data);
        } catch (err) {
            console.error('Error fetching suppliers:', err.message);
        }
    };

    const handleSupplierSelect = (supplierName, index) => {
        const updatedRows = [...rows];
        updatedRows[index] = {
            ...updatedRows[index],
            supplierName: supplierName,
        };
        setRows(updatedRows);
    };


    const fetchItemssss = async (Indent_no) => {
        try {
            const response = await fetch(`https://erpapi.emedha.in/pages/api/pur-indent/${Indent_no}`);
            if (!response.ok) {
                throw new Error('Failed to fetch indent data');
            }
            const data = await response.json();
            console.log(data);
        } catch (err) {
            console.error('Error fetching items:', err.message);
        }
    };

    const handleIndentSelect = (Indent_no) => {
        fetchItemssss(Indent_no);
    };



    const [indentNo, setIndentNo] = useState("");
    const [filteredItems, setFilteredItems] = useState([]);


    const fetchItemss = async (indentNo = '') => {
        try {
            const response = await fetch(`https://erpapi.emedha.in/pages/api/api/pur-indent/${indentNo}`);
            if (!response.ok) {
                throw new Error('Failed to fetch items');
            }
            const data = await response.json();
            setFilteredItems(data);
        } catch (err) {
            console.error('Error fetching items:', err.message);
        }
    };

    useEffect(() => {
        if (indentNo) {
            setFilteredItems(items.filter(item => item.indentNo.includes(indentNo)));
        } else {
            setFilteredItems([]);
        }
    }, [indentNo]);


    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(null);

    const handleSave = async () => {
        const { Location, indentNo, indentDt, indentReqBy, department, itemCd, reqdt, suppid, qty, remarks, approvedqty, approvedby, close } = formData;

        if (!Location || !indentNo || !indentDt) {
            alert("Please fill all required fields");
            return;
        }
        console.log(formData);

        const form = new FormData();
        form.append('Location', Location);
        form.append('indentNo', indentNo);
        form.append('indentDt', indentDt);
        form.append('indentReqBy', indentReqBy);
        form.append('department', department);
        form.append('itemCd', itemCd);
        form.append('reqdt', reqdt);
        form.append('suppid', suppid);
        form.append('qty', qty);
        form.append('remarks', remarks);
        form.append('approvedqty', approvedqty);
        form.append('approvedby', approvedby);
        form.append('close', close);

        try {
            if (isEditing) {
                await axios.put(`https://erpapi.emedha.in/pages/api/pur-indent/:Indent_no/${editId}`, form);
                alert('Item updated successfully!');
            } else {
                const response = await axios.post('hhttps://erpapi.emedha.in/pages/api/pur-indent', form, {
                    headers: { 'Content-Type': 'application/json' },
                });
                alert('Item added successfully!');
            }
        } catch (error) {
            alert('Error saving item');
            console.error('Error saving item:', error);
        }
    };

    const [dropdownVisible1, setDropdownVisible1] = useState(false)


    const [searchTerm, setSearchTerm] = useState("");
    const handleItemSelect = (item) => {
        setSelectedItem(item);
        setSearchTerm(item.itemCd);
        setDropdownVisible1(false)
        setFilteredItems([]);
    };

    const handleEditItem = (item) => {
        setIndentNo(item.indentNo);
        setFormData({
            Location: item.Location,
            Indent_no: item.indentNo,
            Indent_dt: item.indentDt,
            Indent_Req_by: item.indentReqBy,
            Dept_id: item.department,
            itemCd: item.itemCd,
            Req_dt: item.reqdt,
            Supp_id: item.suppid,
            Qty: item.qty,
            Remarks: item.remarks,
            Approved_qty: item.approvedqty,
            Approved_by: item.approvedby,
            Close: item.close
        });

        setEditId(item.id);
        setIsEditing(true);
        setShowForm(true);
        setDropdownVisible1(false);
        setShowViewFormm(false);
    };
    const [showviewformm, setShowViewFormm] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const handleBack = () => { navigate(-1); };

    const toggleForm = () => {
        setShowForm(prevState => !prevState);
        setFormData({ Location: '', indentNo: '', indentDt: '', indentReqBy: '', department: '', itemCd: '', reqdt: '', suppid: '', qty: '', remarks: '', approvedqty: '', approvedby: '', close: '' });
        setIsEditing(false);
        setEditId(null);
        setShowForm(true);
        setShowViewFormm(false);
        setShowSearch(false);
    };

    const handleRefresh = () => {
        setFormData({
            Location: '', indentNo: '', indentDt: '', indentReqBy: '', department: '',
            itemCd: '', reqdt: '', suppid: '', qty: '', remarks: '', approvedqty: '',
            approvedby: '', close: ''
        });
        setRows([...Array(20)].map(() => ({
            itemCd: '', itemDesc: '', itemUOM: '', reqdt: '', supplierName: '', qty: '',
            remarks: '', approvedqty: '', approvedby: '', close: ''
        })));
    };

    const handleViewItem = (item) => {
        setShowViewFormm(true);
        setShowForm(false);
        setDropdownVisible1(false);
    };

    const handleInputChange = (e, index = null) => {
        const { name, value } = e.target;
        if (index !== null) {
            const updatedRows = [...rows];
            updatedRows[index] = {
                ...updatedRows[index],
                [name]: value,
            };
            setRows(updatedRows);
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));

            if (name === 'Location' && value) {
                const selectedLocation = locations.find(location => location.Location_Desc === value);
                if (selectedLocation) {
                    setFormData((prevState) => ({
                        ...prevState,
                        indentNo: selectedLocation.Location_Id,
                    }));
                }
            }
        }
    };



    const [departments, setDepartments] = useState([]);
    const [locations, setLocations] = useState([]);
    const [employe, setEmploye] = useState([]);
    useEffect(() => {
        fetchLocations();
        fetchDepartments();
        fetchEmploye();
    }, []);
    const fetchLocations = async () => {
        try {
            const response = await fetch('https://erpapi.emedha.in/pages/api/api/location');
            if (!response.ok) {
                throw new Error('Failed to fetch locations');
            }
            const data = await response.json();
            setLocations(data);
        } catch (err) {
            console.error('Error fetching locations:', err.message);
        }
    };
    const fetchDepartments = async () => {
        try {
            const response = await fetch('https://erpapi.emedha.in/pages/api/api/depertament');
            if (!response.ok) {
                throw new Error('Failed to fetch departments');
            }
            const data = await response.json();
            setDepartments(data);
        } catch (err) {
            console.error('Error fetching departments:', err.message);
        }
    };
    const fetchEmploye = async () => {
        try {
            const response = await fetch('https://erpapi.emedha.in/pages/api/api/employeee');
            if (!response.ok) {
                throw new Error('Failed to fetch employeee');
            }
            const data = await response.json();
            setEmploye(data);
        } catch (err) {
            console.error('Error fetching employeee:', err.message);
        }
    };

    const userPermissions = user ? {
        add: user.add_permission,
        edit: user.edit_permission,
        view: user.view_permission,
        save: user.save_permission,
        approve: user.approve_permission,
        print: user.print_permission,
        close: user.close_permission,
        clear: user.clear_screen_permission
    } : {};

    const handleApprove = (id) => {

    };

    const handlePrint = () => {

    };


    return (
        <>
            <div style={{ margin: 'auto', backgroundColor: '#dbc4a9', padding: '24px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <img src={A1} alt="item" style={{ width: "20%" }} />
                    <h1 style={{ color: "darkblue", fontWeight: 'bold', fontSize: "1.95rem", fontWeight: "1000", marginBottom: "1rem" }}>
                        PURCHASE INDENT
                    </h1>
                    <div style={{ display: "flex", gap: "1rem", cursor: 'pointer' }}>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "20px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.add && userPermissions.add !== 0 ? "pointer" : "not-allowed", opacity: userPermissions.add && userPermissions.add !== 0 ? 1 : 0.5 }}>
                            <FaPlus size={30} onClick={() => { if (userPermissions.add && userPermissions.add !== 0) { toggleForm(); setShowSearch(false); } }} />
                        </div>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "20px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.edit && userPermissions.edit !== 0 ? "pointer" : "not-allowed", opacity: userPermissions.edit && userPermissions.edit !== 0 ? 1 : 0.5 }}>
                            <MdEdit size={30} onClick={() => {
                                if (userPermissions.edit && userPermissions.edit !== 0) {
                                    setShowForm(true); setShowSearch(true);
                                    if (selectedItem) {
                                        setFormData({ Location: selectedItem.Location, indentNo: selectedItem.indentNo, indentDt: selectedItem.indentDt, indentReqBy: selectedItem.indentReqBy, department: selectedItem.department, itemCd: selectedItem.itemCd, reqdt: selectedItem.reqdt, suppid: selectedItem.suppid, qty: selectedItem.qty, remarks: selectedItem.remarks, approvedqty: selectedItem.approvedqty, approvedby: selectedItem.approvedby, close: selectedItem.close, });
                                    } else {
                                        setFormData({ Location: '', indentNo: '', indentDt: '', indentReqBy: '', department: '', itemCd: '', reqdt: '', suppid: '', qty: '', remarks: '', approvedqty: '', approvedby: '', close: '' });
                                    }
                                } else { alert("You do not have permission to edit."); }
                            }} />
                        </div>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "20px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.view && userPermissions.view !== 0 ? "pointer" : "not-allowed", opacity: userPermissions.view && userPermissions.view !== 0 ? 1 : 0.5 }}>
                            <LuView size={30} onClick={userPermissions.view && userPermissions.view !== 0 ? handleViewItem : null} />
                        </div>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "20px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.clear && userPermissions.clear !== 0 ? "pointer" : "not-allowed", opacity: userPermissions.clear && userPermissions.clear !== 0 ? 1 : 0.5 }}>
                            <HiOutlineRefresh size={30} onClick={userPermissions.clear && userPermissions.clear !== 0 ? handleRefresh : null} />
                        </div>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "20px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.save && userPermissions.save !== 0 ? "pointer" : "not-allowed", opacity: userPermissions.save && userPermissions.save !== 0 ? 1 : 0.5 }}>
                            <FaSave size={30} onClick={userPermissions.save && userPermissions.save !== 0 ? handleSave : null} />
                        </div>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "20px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.approve && userPermissions.approve !== 0 ? "pointer" : "not-allowed", opacity: userPermissions.approve && userPermissions.approve !== 0 ? 1 : 0.5 }}>
                            <FaCheck size={30} onClick={userPermissions.approve && userPermissions.approve !== 0 ? () => handleApprove(selectedItem.id) : null} />
                        </div>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "20px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.print && userPermissions.print !== 0 ? "pointer" : "not-allowed", opacity: userPermissions.print && userPermissions.print !== 0 ? 1 : 0.5 }}>
                            <IoPrint size={30} onClick={userPermissions.print && userPermissions.print !== 0 ? handlePrint : null} />
                        </div>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "20px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.close && userPermissions.close !== 0 ? "pointer" : "not-allowed", opacity: userPermissions.close && userPermissions.close !== 0 ? 1 : 0.5 }}>
                            <RiCloseLargeFill size={30} onClick={userPermissions.close && userPermissions.close !== 0 ? handleBack : null} />
                        </div>
                    </div>
                </div>

                {showForm && (
                    <>
                        <div>
                            <div className='mt-5' style={{ marginBottom: '16px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '52px', color: 'darkblue' }}>Location:</label>
                                    <select name="Location" value={formData.Location || ''} onChange={handleInputChange} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '8px', margin: '4px 0', width: '20%', outline: 'none', backgroundColor: 'transparent' }}>
                                        <option value="">Select</option>
                                        {locations.map(location => (<option key={location.Location_Id} value={location.Location_Desc}>{location.Location_Desc}</option>))}
                                    </select>

                                    {showSearch && (
                                        <div style={{ display: 'flex', alignItems: 'flex-start', width: '80%', position: 'relative', paddingLeft: '50px' }}>
                                            <label className="me-4 mt-3" style={{ color: "darkblue", fontWeight: 'bold' }}><FaSearch /></label>
                                            <input type="text" placeholder="Search by indent " value={searchTerm}
                                                onChange={(e) => { setSearchTerm(e.target.value); setDropdownVisible1(true); }}
                                                style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '8px', margin: '4px 0', width: '20%', outline: 'none', backgroundColor: 'transparent' }} />
                                            {filteredItems.length > 0 && dropdownVisible1 && (
                                                <ul style={{ maxHeight: "150px", overflowY: "auto", border: "1px solid #e2e8f0", width: "100%", position: "absolute", backgroundColor: "#fff", zIndex: 1, marginTop: '5px', padding: 0, top: '100%' }}>
                                                    {filteredItems.map((item) => (
                                                        <li key={item.id}
                                                            onClick={() => handleItemSelect(item)}
                                                            style={{ padding: "0.5rem", cursor: "pointer", listStyleType: "none", borderBottom: "1px solid #e2e8f0" }}>
                                                            {item.itemCd}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    )}

                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '41px', color: 'darkblue' }}>Indent No: </label>
                                    <input type="number" name="indentNo" value={formData.indentNo || ''} onChange={handleInputChange} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '8px', margin: '4px 0', width: '20%', outline: 'none', backgroundColor: 'transparent' }} readOnly />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '14px', color: 'darkblue' }}>Indent Req By:</label>
                                    <select name="indentReqBy" value={formData.indentReqBy || ''} onChange={handleInputChange} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '8px', margin: '4px 0', width: '20%', outline: 'none', backgroundColor: 'transparent' }}>
                                        <option value="">Select</option>
                                        {employe.map(employe => (<option key={employe.Emp_id} value={employe.Emp_Name}>{employe.Emp_Name}</option>))}
                                    </select>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '32px', color: 'darkblue' }}>Department</label>
                                    <select name="department" value={formData.department || ''} onChange={handleInputChange} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '8px', margin: '4px 0', width: '20%', outline: 'none', backgroundColor: 'transparent' }}>
                                        <option value="">Select</option>
                                        {departments.map(department => (<option key={department.Department_Id} value={department.Department_Id}>{department.Dept_name}</option>))}
                                    </select>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '30px', color: 'darkblue' }}>Indent Date:</label>
                                    <input type="text" name="indentDt" value={formData.indentDt || ''} onChange={handleInputChange} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '8px', margin: '4px 0', width: '20%', outline: 'none', backgroundColor: 'transparent' }} />
                                </div>
                            </div>

                            <div style={{ overflowY: 'auto', maxHeight: '250px' }}>
                                <table style={{ minWidth: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Sl No.</th>
                                            <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Item Code</th>
                                            <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Item Description</th>
                                            <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>UOM</th>
                                            <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Req. Dt.</th>
                                            <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Supplier Name</th>
                                            <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Indent qty</th>
                                            <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Remarks</th>
                                            <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Approved Qty</th>
                                            <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Approved by</th>
                                            <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Short close</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows.map((row, index) => (
                                            <tr key={index}>
                                                <td style={{ border: '1px solid black' }}>
                                                    {index + 1}
                                                </td>
                                                <td style={{ border: '1px solid black' }}>
                                                    <select style={{ border: 'black', outline: 'black', backgroundColor: 'transparent', color: 'darkblue', }} value={row.itemCd} onChange={(e) => handleItemSelects(e.target.value, index)} >
                                                        <option value="">Select</option>
                                                        {items.map((item) => (
                                                            <option key={item.id} value={item.itemCd}>
                                                                {item.itemCd}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </td>
                                                <td style={{ border: '1px solid black' }}>
                                                    <input type="text" style={{ border: 'black', outline: 'black', backgroundColor: 'transparent', }} value={row.itemDesc} readOnly />
                                                </td>
                                                <td style={{ border: '1px solid black' }}>
                                                    <input type="text" style={{ border: 'black', outline: 'black', backgroundColor: 'transparent', }} value={row.itemUOM} readOnly />
                                                </td>
                                                <td style={{ border: '1px solid black' }}>
                                                    <input name="reqdt" value={row.reqdt || ''} onChange={(e) => handleInputChange(e, index)} type="text" style={{ border: 'black', outline: 'black', backgroundColor: 'transparent', }} />
                                                </td>
                                                <td style={{ border: '1px solid black' }}>
                                                    <select
                                                        style={{ border: 'black', outline: 'black', backgroundColor: 'transparent', color: 'darkblue', }} value={row.supplierName} onChange={(e) => handleSupplierSelect(e.target.value, index)}  >
                                                        <option value="">Select</option>
                                                        {suppliers.map((supplier) => (
                                                            <option key={supplier.id} value={supplier.Supp_Nm}>
                                                                {supplier.Supp_Nm}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </td>

                                                <td style={{ border: '1px solid black' }}>
                                                    <input name="qty" value={row.qty || ''} onChange={(e) => handleInputChange(e, index)} type="text" style={{
                                                        border: 'black', outline: 'black', backgroundColor: 'transparent',
                                                    }} />
                                                </td>

                                                <td style={{ border: '1px solid black' }}>
                                                    <input name="remarks" value={row.remarks || ''} onChange={(e) => handleInputChange(e, index)} type="text" style={{ border: 'black', outline: 'black', backgroundColor: 'transparent', }} />
                                                </td>

                                                <td style={{ border: '1px solid black' }}>
                                                    <input name="approvedqty" value={row.approvedqty || ''} onChange={(e) => handleInputChange(e, index)} type="text" style={{ border: 'black', outline: 'black', backgroundColor: 'transparent', }}
                                                    />
                                                </td>

                                                <td style={{ border: '1px solid black' }}>
                                                    <input name="approvedby" value={row.approvedby || ''} onChange={(e) => handleInputChange(e, index)} type="text" style={{ border: 'black', outline: 'black', backgroundColor: 'transparent', }}
                                                    />
                                                </td>

                                                <td style={{ border: '1px solid black' }}>
                                                    <input name="close" value={row.close || ''} onChange={(e) => handleInputChange(e, index)} type="text" style={{ border: 'black', outline: 'black', backgroundColor: 'transparent', }}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </>
                )}
            </div>

            {showviewformm && !showForm && selectedItem && (
                <>
                    <div style={{ margin: 'auto', backgroundColor: '#dbc4a9', padding: '24px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>

                        <div style={{ marginBottom: '16px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <label style={{ marginRight: '52px', color: 'darkblue' }}>Location:</label>
                                <select name="Location" value={selectedItem.Location || ''} onChange={handleInputChange} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '8px', margin: '4px 0', width: '20%', outline: 'none', backgroundColor: 'transparent' }}>
                                    <option value="">Select</option>
                                    {locations.map(location => (<option key={location.Location_Id} value={location.Location_Desc}>{location.Location_Desc}</option>))}
                                </select>

                                <div style={{ display: 'flex', alignItems: 'flex-start', width: '80%', position: 'relative', paddingLeft: '50px' }}>
                                    <label className="me-4 mt-3" style={{ color: "darkblue", fontWeight: 'bold' }}><FaSearch /></label>
                                    <input type="text" placeholder="Search by Indent" value={searchTerm}
                                        onChange={(e) => { setSearchTerm(e.target.value); setDropdownVisible1(true); }}
                                        style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '8px', margin: '4px 0', width: '20%', outline: 'none', backgroundColor: 'transparent' }} />
                                    {filteredItems.length > 0 && dropdownVisible1 && (
                                        <ul style={{ maxHeight: "150px", overflowY: "auto", border: "1px solid #e2e8f0", width: "100%", position: "absolute", backgroundColor: "#fff", zIndex: 1, marginTop: '5px', padding: 0, top: '100%' }}>
                                            {filteredItems.map((item) => (
                                                <li key={item.id}
                                                    onClick={() => handleItemSelect(item)}
                                                    style={{ padding: "0.5rem", cursor: "pointer", listStyleType: "none", borderBottom: "1px solid #e2e8f0" }}>
                                                    {item.itemCd}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>

                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <label style={{ marginRight: '41px', color: 'darkblue' }}>Indent No: </label>
                                <input type="number" name="indentNo" value={selectedItem.indentNo || ''} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '8px', margin: '4px 0', width: '20%', outline: 'none', backgroundColor: 'transparent' }} readOnly />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <label style={{ marginRight: '14px', color: 'darkblue' }}>Indent Req By:</label>
                                <select name="indentReqBy" value={selectedItem.indentReqBy || ''} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '8px', margin: '4px 0', width: '20%', outline: 'none', backgroundColor: 'transparent' }}>
                                    <option value="">Select</option>
                                    {employe.map(employe => (<option key={employe.Emp_id} value={employe.Emp_Name}>{employe.Emp_Name}</option>))}
                                </select>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <label style={{ marginRight: '32px', color: 'darkblue' }}>Department</label>
                                <select name="department" value={selectedItem.department || ''} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '8px', margin: '4px 0', width: '20%', outline: 'none', backgroundColor: 'transparent' }}>
                                    <option value="">Select</option>
                                    {departments.map(department => (<option key={department.Department_Id} value={department.Department_Id}>{department.Dept_name}</option>))}
                                </select>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <label style={{ marginRight: '30px', color: 'darkblue' }}>Indent Date:</label>
                                <input type="text" name="indentDt" value={selectedItem.indentDt || ''} style={{ border: 'none', borderLeft: '2px solid #D1D5DB', padding: '8px', margin: '4px 0', width: '20%', outline: 'none', backgroundColor: 'transparent' }} />
                            </div>
                        </div>

                        <div style={{ overflowY: 'auto', maxHeight: '250px' }}>
                            <table style={{ minWidth: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
                                <thead>
                                    <tr>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Sl No.</th>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Item Code</th>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Item Description</th>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>UOM</th>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Req. Dt.</th>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Supplier Name</th>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Indent qty</th>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Remarks</th>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Approved Qty</th>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Approved by</th>
                                        <th style={{ border: '1px solid black', textAlign: 'center', color: 'darkblue' }}>Short close</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows.map((row, index) => (
                                        <tr key={index}>

                                            <td style={{ border: '1px solid black' }}>
                                                {index + 1}
                                            </td>
                                            <td style={{ border: '1px solid black' }}>
                                                <select
                                                    style={{
                                                        border: 'black',
                                                        outline: 'black',
                                                        backgroundColor: 'transparent',
                                                        color: 'darkblue',
                                                    }}
                                                    value={row.itemCd}

                                                >
                                                    <option value="">Select</option>
                                                    {items.map((item) => (
                                                        <option key={item.id} value={item.itemCd}>
                                                            {item.itemCd}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>


                                            <td style={{ border: '1px solid black' }}>
                                                <input
                                                    type="text"
                                                    style={{
                                                        border: 'black',
                                                        outline: 'black',
                                                        backgroundColor: 'transparent',
                                                    }}
                                                    value={row.itemDesc}
                                                    readOnly
                                                />
                                            </td>

                                            {/* UOM */}
                                            <td style={{ border: '1px solid black' }}>
                                                <input
                                                    type="text"
                                                    style={{
                                                        border: 'black',
                                                        outline: 'black',
                                                        backgroundColor: 'transparent',
                                                    }}
                                                    value={row.itemUOM}
                                                    readOnly
                                                />
                                            </td>

                                            {/* Request Date */}
                                            <td style={{ border: '1px solid black' }}>
                                                <input
                                                    name="reqdt"
                                                    value={row.reqdt || ''}

                                                    type="text"
                                                    style={{
                                                        border: 'black',
                                                        outline: 'black',
                                                        backgroundColor: 'transparent',
                                                    }}
                                                />
                                            </td>

                                            {/* Supplier Name */}
                                            <td style={{ border: '1px solid black' }}>
                                                <select
                                                    style={{
                                                        border: 'black',
                                                        outline: 'black',
                                                        backgroundColor: 'transparent',
                                                        color: 'darkblue',
                                                    }}
                                                    value={row.supplierName}

                                                >
                                                    <option value="">Select</option>
                                                    {suppliers.map((supplier) => (
                                                        <option key={supplier.id} value={supplier.Supp_Nm}>
                                                            {supplier.Supp_Nm}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>

                                            <td style={{ border: '1px solid black' }}>
                                                <input
                                                    name="qty"
                                                    value={row.qty || ''}

                                                    type="text"
                                                    style={{
                                                        border: 'black',
                                                        outline: 'black',
                                                        backgroundColor: 'transparent',
                                                    }}
                                                />
                                            </td>

                                            <td style={{ border: '1px solid black' }}>
                                                <input
                                                    name="remarks"
                                                    value={row.remarks || ''}

                                                    type="text"
                                                    style={{
                                                        border: 'black',
                                                        outline: 'black',
                                                        backgroundColor: 'transparent',
                                                    }}
                                                />
                                            </td>

                                            <td style={{ border: '1px solid black' }}>
                                                <input
                                                    name="approvedqty"
                                                    value={row.approvedqty || ''}

                                                    type="text"
                                                    style={{
                                                        border: 'black',
                                                        outline: 'black',
                                                        backgroundColor: 'transparent',
                                                    }}
                                                />
                                            </td>

                                            <td style={{ border: '1px solid black' }}>
                                                <input
                                                    name="approvedby"
                                                    value={row.approvedby || ''}

                                                    type="text"
                                                    style={{
                                                        border: 'black',
                                                        outline: 'black',
                                                        backgroundColor: 'transparent',
                                                    }}
                                                />
                                            </td>

                                            <td style={{ border: '1px solid black' }}>
                                                <input
                                                    name="close"
                                                    value={row.close || ''}

                                                    type="text"
                                                    style={{
                                                        border: 'black',
                                                        outline: 'black',
                                                        backgroundColor: 'transparent',
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>
                    </div>

                </>
            )}
        </>
    );
};

export default Indent;

