import React, { useEffect, useState } from "react";
import { FaCheck, FaPlus } from "react-icons/fa6";
import { MdEdit } from "react-icons/md";
import { LuView } from "react-icons/lu";
import { HiOutlineRefresh } from "react-icons/hi";
import { FaSave } from "react-icons/fa";
import { IoPrint } from "react-icons/io5";
import { RiCloseLargeFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import A1 from '../Images/logo-w.png';
import axios from 'axios';

const Suppliermst = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        Supp_Nm: '', Supp_Address: '', Contact_Person: '', Contact_Person_Ph: '', Contact_Person_Email: '',
        Credit_Days: '', TIN: '', PAN: '', TDS_Section: '', Bank_Acc_No: '',
        Bank_Branch: '', IFSC: '', Gst_Reg: ''
    });
    const [showForm, setShowForm] = useState(false);
    const [items, setItems] = useState([]);
    const user = JSON.parse(localStorage.getItem('user'));
    const user_Id = user ? user.id : null;

    useEffect(() => {
        fetchItems();
    }, [user_Id]);

    const fetchItems = async () => {
        try {
            const response = await fetch('https://erpapi.emedha.in/pages/api/api/suppliers');
            if (!response.ok) {
                throw new Error('Failed to fetch items');
            }
            const data = await response.json();
            setItems(data);
        } catch (err) {
            console.error('Error fetching items:', err.message);
        }
    };

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredItems, setFilteredItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleItemSelect = (item) => {
        setSelectedItem(item);
        setSearchTerm(item.itemCd);
        setDropdownVisible1(true)
        setFilteredItems([]);
    };

    const fetchItemss = async (searchTerm = '') => {
        try {
            const response = await fetch(`https://erpapi.emedha.in/pages/api/api/suppliers?searchTerm=${searchTerm}`);
            if (!response.ok) {
                throw new Error('Failed to fetch items');
            }
            const data = await response.json();
            setFilteredItems(data);
        } catch (err) {
            console.error('Error fetching items:', err.message);
        }
    };

    useEffect(() => {
        if (searchTerm) {
            fetchItemss(searchTerm);
        } else {
            setFilteredItems([]);
        }
    }, [searchTerm]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(null);

    const handleSaveItem = async () => {

        if (!formData.Supp_Nm || !formData.Bank_Acc_No || !formData.IFSC) {
            alert('Supp_Nm, Bank_Acc_No, and IFSC are required!');
            return;
        }

        const payload = {
            Supp_Nm: formData.Supp_Nm,
            Supp_Address: formData.Supp_Address,
            Contact_Person: formData.Contact_Person,
            Contact_Person_Ph: formData.Contact_Person_Ph,
            Contact_Person_Email: formData.Contact_Person_Email,
            Credit_Days: formData.Credit_Days,
            TIN: formData.TIN,
            PAN: formData.PAN,
            TDS_Section: formData.TDS_Section,
            Bank_Acc_No: formData.Bank_Acc_No,
            Bank_Branch: formData.Bank_Branch,
            IFSC: formData.IFSC,
            Gst_Reg: formData.Gst_Reg,
        };

        try {
            if (isEditing) {
                // Update existing supplier
                await axios.put(`https://erpapi.emedha.in/pages/api/api/suppliers/${editId}`, payload, {
                    headers: { 'Content-Type': 'application/json' }
                });
                setItems(items.map(item => (item.id === editId ? { ...formData, id: editId } : item)));
                alert('Supplier updated successfully!');
            } else {
                // Add new supplier
                const response = await axios.post('https://erpapi.emedha.in/pages/api/api/suppliers', payload, {
                    headers: { 'Content-Type': 'application/json' }
                });
                setItems([...items, { ...formData, id: response.data.id }]);
                alert('Supplier added successfully!');
            }
            setShowForm(false);
        } catch (error) {
            console.error('Error saving supplier:', error);
        }
    };

    const handleEditItem = (item) => {
        setSearchTerm(item.Supp_Nm);
        setFormData({
            Supp_Nm: item.Supp_Nm,
            Supp_Address: item.Supp_Address,
            Contact_Person: item.Contact_Person,
            Contact_Person_Ph: item.Contact_Person_Ph,
            Contact_Person_Email: item.Contact_Person_Email,
            Credit_Days: item.Credit_Days,
            TIN: item.TIN,
            PAN: item.PAN,
            TDS_Section: item.TDS_Section,
            Bank_Acc_No: item.Bank_Acc_No,
            Bank_Branch: item.Bank_Branch,
            IFSC: item.IFSC,
            Gst_Reg: item.Gst_Reg
        });

        setEditId(item.id);
        setIsEditing(true);
        setShowForm(true);
        setDropdownVisible1(false);
        setShowFormm(false);
    };
    const [showSearch, setShowSearch] = useState(false);
    const [dropdownVisible1, setDropdownVisible1] = useState(false);
    const [showformm, setShowFormm] = useState(false);
    const handleBack = () => { navigate(-1); };

    const toggleForm = () => {
        setShowForm(prevState => !prevState);
        setFormData({ Supp_Nm: '', Supp_Address: '', Contact_Person: '', Contact_Person_Ph: '', Contact_Person_Email: '', Credit_Days: '', TIN: '', PAN: '', TDS_Section: '', Bank_Acc_No: '', Bank_Branch: '', IFSC: '', Gst_Reg: '' });
        setIsEditing(false);
        setEditId(null);
        setShowForm(true);
        setShowFormm(false);
        setShowSearch(false);
    };

    const handleRefresh = () => {
        setSelectedItem({ Supp_Nm: '', Supp_Address: '', Contact_Person: '', Contact_Person_Ph: '', Contact_Person_Email: '', Credit_Days: '', TIN: '', PAN: '', TDS_Section: '', Bank_Acc_No: '', Bank_Branch: '', IFSC: '', Gst_Reg: '' });
        setFormData({ Supp_Nm: '', Supp_Address: '', Contact_Person: '', Contact_Person_Ph: '', Contact_Person_Email: '', Credit_Days: '', TIN: '', PAN: '', TDS_Section: '', Bank_Acc_No: '', Bank_Branch: '', IFSC: '', Gst_Reg: '' });
    };

    const handleViewItem = (item) => {
        setSelectedItem(item);
        setShowFormm(true);
        setShowForm(false);
    };

    console.log(user)

    const userPermissions = user ? {
        add: user.add_permission,
        edit: user.edit_permission,
        view: user.view_permission,
        save: user.save_permission,
        approve: user.approve_permission,
        print: user.print_permission,
        close: user.close_permission,
        clear: user.clear_screen_permission
    } : {};


    const handleApprove = (id) => {
        if (id) {
            axios
                .put(`https://erpapi.emedha.in/pages/api/api/supplier-mst/approve/${id}`)
                .then((response) => {
                    console.log('Item approved successfully:', response.data);
                    setItems((prevItems) =>
                        prevItems.map((item) =>
                            item.id === id ? { ...item, isApproved: true } : item
                        )
                    );
                    alert('Item approved successfully');
                })
                .catch((error) => {
                    console.error('Error approving item:', error);
                    alert('Failed to approve item');
                });
        } else {
            alert("Item ID is invalid.");
        }
    };


    const handlePrint = () => {
        const printContent = document.getElementById("print-content");
        if (printContent) {
            const originalContent = document.body.innerHTML;
            document.body.innerHTML = printContent.innerHTML;
            window.print();
            document.body.innerHTML = originalContent;
        } else {
            alert("Print content not found!");
        }
    };

    return (
        <>
            <div style={{ backgroundColor: "#dbc4a9", padding: "1rem", margin: "0 auto", padding: "1rem", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }} >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <img src={A1} alt="item" style={{ width: "20%" }} />
                    <h1 style={{ color: "darkblue", fontWeight: 'bold', fontSize: "1.95rem", fontWeight: "1000", marginBottom: "1rem" }}>
                        SUPPLIER MASTER
                    </h1>
                    <div style={{ display: "flex", gap: "1rem", cursor: 'pointer' }}>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.add === 0 ? "not-allowed" : "pointer", opacity: userPermissions.add === 0 ? 0.5 : 1, }}>
                            <FaPlus size={30} onClick={() => { if (userPermissions.add && userPermissions.add !== 0) { toggleForm(); setShowSearch(false); } }} />
                        </div>
                        <div>
                            <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.edit === 0 ? "not-allowed" : "pointer", opacity: userPermissions.edit === 0 ? 0.5 : 1, }}>
                                <MdEdit size={30} onClick={() => {
                                    if (userPermissions.edit && userPermissions.edit !== 0) {
                                        setShowForm(true); setShowSearch(true);
                                        if (selectedItem) {
                                            setFormData({ Supp_Nm: selectedItem.Supp_Nm, Supp_Address: selectedItem.Supp_Address, Contact_Person: selectedItem.Contact_Person, Contact_Person_Ph: selectedItem.Contact_Person_Ph, Contact_Person_Email: selectedItem.Contact_Person_Email, Credit_Days: selectedItem.Credit_Days, TIN: selectedItem.TIN, PAN: selectedItem.PAN, TDS_Section: selectedItem.TDS_Section, Bank_Acc_No: selectedItem.Bank_Acc_No, Bank_Branch: selectedItem.Bank_Branch, IFSC: selectedItem.IFSC, Gst_Reg: selectedItem.Gst_Reg });
                                        } else {
                                            setFormData({ Supp_Nm: '', Supp_Address: '', Contact_Person: '', Contact_Person_Ph: '', Contact_Person_Email: '', Credit_Days: '', TIN: '', PAN: '', TDS_Section: '', Bank_Acc_No: '', Bank_Branch: '', IFSC: '', Gst_Reg: '' });
                                        }
                                    } else { alert("You do not have permission to edit."); }
                                }} />
                            </div>
                        </div>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.view === 0 ? "not-allowed" : "pointer", opacity: userPermissions.view === 0 ? 0.5 : 1, }}>
                            <LuView size={30} onClick={userPermissions.view && userPermissions.view !== 0 ? handleViewItem : null} />
                        </div>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.clear === 0 ? "not-allowed" : "pointer", opacity: userPermissions.clear === 0 ? 0.5 : 1, }}>
                            <HiOutlineRefresh size={30} onClick={userPermissions.clear && userPermissions.clear !== 0 ? handleRefresh : null} />
                        </div>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.save === 0 ? "not-allowed" : "pointer", opacity: userPermissions.save === 0 ? 0.5 : 1, }}>
                            <FaSave size={30} onClick={userPermissions.save && userPermissions.save !== 0 ? handleSaveItem : null} />
                        </div>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.approve === 0 ? "not-allowed" : "pointer", opacity: userPermissions.approve === 0 ? 0.5 : 1, }}>
                            <FaCheck size={30} onClick={userPermissions.approve && userPermissions.approve !== 0 ? () => handleApprove(selectedItem.id) : null} />
                        </div>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.print === 0 ? "not-allowed" : "pointer", opacity: userPermissions.print === 0 ? 0.5 : 1, }}>
                            <IoPrint size={30} onClick={userPermissions.print && userPermissions.print !== 0 ? handlePrint : null} />
                        </div>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.close === 0 ? "not-allowed" : "pointer", opacity: userPermissions.close === 0 ? 0.5 : 1, }}>
                            <RiCloseLargeFill size={30} onClick={userPermissions.close && userPermissions.close !== 0 ? handleBack : null} />
                        </div>

                    </div>
                </div>
                <hr />
                {showForm && (
                    <>
                        <div className="container">
                            {showSearch && (
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '25%', position: 'relative' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>Search by Item CD</label>
                                    <input type="text" placeholder="Search by Item CD" value={searchTerm}
                                        onChange={(e) => { setSearchTerm(e.target.value); setDropdownVisible1(true); }}
                                        style={{ marginBottom: "1rem", padding: "0.25rem", border: "1px solid #e2e8f0", width: '100%', borderRadius: '20px' }} />
                                    {filteredItems.length > 0 && dropdownVisible1 && (
                                        <ul style={{ maxHeight: "150px", overflowY: "auto", border: "1px solid #e2e8f0", width: "100%", position: "absolute", backgroundColor: "#fff", zIndex: 1, marginTop: '5px', padding: 0, top: '100%' }}>
                                            {filteredItems.map((item) => (
                                                <li
                                                    key={item.id}
                                                    onClick={() => handleEditItem(item)}
                                                    style={{ padding: "0.5rem", cursor: "pointer", listStyleType: "none", borderBottom: "1px solid #e2e8f0" }}>
                                                    {item.Supp_Nm}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            )}
                            <div className="row">
                                <div className="col-md-4 mb-1">
                                    <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Supplier Name</label>
                                    <input type="text" className="form-control" name="Supp_Nm" value={formData.Supp_Nm} onChange={handleInputChange} />
                                </div>
                                <div className="col-md-6 mb-1">
                                    <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Supplier Address</label>
                                    <input type="text" className="form-control" name="Supp_Address" value={formData.Supp_Address} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className="mt-3 row">
                                <div className="col-md-4 mb-1">
                                    <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Contact Person</label>
                                    <input type="text" className="form-control" name="Contact_Person" value={formData.Contact_Person} onChange={handleInputChange} />
                                </div>
                                <div className="col-md-4 mb-1">
                                    <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Contact Person Phone</label>
                                    <input type="tel" className="form-control" name="Contact_Person_Ph" value={formData.Contact_Person_Ph} onChange={handleInputChange} />
                                </div>
                                <div className="col-md-4 mb-1">
                                    <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Contact Person Email</label>
                                    <input type="email" className="form-control" name="Contact_Person_Email" value={formData.Contact_Person_Email} onChange={handleInputChange} />
                                </div>
                            </div>

                            <div>
                                <label className="h5" style={{ color: 'darkblue', fontWeight: 'bold' }}>SUPPLIER CHARACTERISTICS</label>
                            </div>

                            <div className="border p-3">
                                <div className="row">
                                    <div className="col-md-4 mb-1">
                                        <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Credit Days</label>
                                        <input type="text" className="form-control" name="Credit_Days" value={formData.Credit_Days} onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-3 mb-1">
                                        <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>TIN</label>
                                        <input type="text" className="form-control" name="TIN" value={formData.TIN} onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-3 mb-1">
                                        <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>PAN</label>
                                        <input type="text" className="form-control" name="PAN" value={formData.PAN} onChange={handleInputChange} />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-4 mb-1">
                                        <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>TDS Section</label>
                                        <input type="text" className="form-control" name="TDS_Section" value={formData.TDS_Section} onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-3 mb-1">
                                        <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Bank Account Number</label>
                                        <input type="text" className="form-control" name="Bank_Acc_No" value={formData.Bank_Acc_No} onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-3 mb-1">
                                        <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Bank Branch</label>
                                        <input type="text" className="form-control" name="Bank_Branch" value={formData.Bank_Branch} onChange={handleInputChange} />
                                    </div>
                                </div>

                                <div className="row mt-3 mb-1">
                                    <div className="col-md-4 mb-1">
                                        <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>IFSC</label>
                                        <input type="text" className="form-control" name="IFSC" value={formData.IFSC} onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-3 mb-1">
                                        <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>GST Registration</label>
                                        <input type="text" className="form-control" name="Gst_Reg" value={formData.Gst_Reg} onChange={handleInputChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

            </div>

            {showformm && !showForm && selectedItem && (
                <>
                    <div style={{ backgroundColor: "#dbc4a9", padding: "1rem", padding: "1rem" }} >
                        <div className="container">

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '25%', position: 'relative' }}>
                                <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>Search by Supplier Name</label>
                                <input type="text" placeholder="Search by Supplier Name" value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    style={{ marginBottom: "1rem", padding: "0.25rem", border: "1px solid #e2e8f0", width: '100%', borderRadius: '20px' }} />
                                {filteredItems.length > 0 && (
                                    <ul style={{ maxHeight: "150px", overflowY: "auto", border: "1px solid #e2e8f0", width: "100%", position: "absolute", backgroundColor: "#fff", zIndex: 1, marginTop: '5px', padding: 0, top: '100%' }}>
                                        {filteredItems.map((item) => (
                                            <li key={item.id} onClick={() => handleItemSelect(item)} style={{ padding: "0.5rem", cursor: "pointer", listStyleType: "none", borderBottom: "1px solid #e2e8f0" }}>
                                                {item.Supp_Nm}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>

                            <div className=" row">
                                <div className="col-md-4 mb-1">
                                    <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Supplier Name</label>
                                    <input className="form-control" name="Supp_Nm" value={selectedItem.Supp_Nm || ''} />
                                </div>

                                <div className="col-md-6 mb-1">
                                    <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Supplier Address</label>
                                    <input className="form-control" name="Supp_Address" value={selectedItem.Supp_Address || ''} />
                                </div>
                            </div>

                            <div className="mt-3 row">
                                <div className="col-md-4 mb-1">
                                    <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Contact Person</label>
                                    <input className="form-control" name="Contact_Person" value={selectedItem.Contact_Person || ''} />
                                </div>

                                <div className="col-md-4 mb-1">
                                    <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Contact Person Phone</label>
                                    <input className="form-control" name="Contact_Person_Ph" value={selectedItem.Contact_Person_Ph || ''} />
                                </div>

                                <div className="col-md-4 mb-1">
                                    <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Contact Person Email</label>
                                    <input className="form-control" name="Contact_Person_Email" value={selectedItem.Contact_Person_Email || ''} />
                                </div>
                            </div>

                            <div className="">
                                <label className="h5" style={{ color: 'darkblue', fontWeight: 'bold' }}>SUPPLIER CHARACTERISTICS</label>
                            </div>

                            <div className="border p-3">
                                <div className="row">
                                    <div className="col-md-4 mb-1">
                                        <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Credit Days</label>
                                        <input className="form-control" name="Credit_Days" value={selectedItem.Credit_Days || ''} />
                                    </div>

                                    <div className="col-md-3 mb-1">
                                        <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>TIN</label>
                                        <input className="form-control" name="TIN" value={selectedItem.TIN || ''} />
                                    </div>

                                    <div className="col-md-3 mb-1">
                                        <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>PAN</label>
                                        <input className="form-control" name="PAN" value={selectedItem.PAN || ''} />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-4 mb-1">
                                        <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>TDS Section</label>
                                        <input className="form-control" name="TDS_Section" value={selectedItem.TDS_Section || ''} />
                                    </div>

                                    <div className="col-md-3 mb-1">
                                        <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Bank Account Number</label>
                                        <input className="form-control" name="Bank_Acc_No" value={selectedItem.Bank_Acc_No || ''} />
                                    </div>

                                    <div className="col-md-3 mb-1">
                                        <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>Bank Branch</label>
                                        <input className="form-control" name="Bank_Branch" value={selectedItem.Bank_Branch || ''} />
                                    </div>
                                </div>

                                <div className="row mt-3 mb-1">
                                    <div className="col-md-4 mb-1">
                                        <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>IFSC</label>
                                        <input className="form-control" name="IFSC" value={selectedItem.IFSC || ''} />
                                    </div>

                                    <div className="col-md-3 mb-1">
                                        <label className="form-label" style={{ color: 'darkblue', fontWeight: 'bold' }}>GST Registration</label>
                                        <input className="form-control" name="Gst_Reg" value={selectedItem.Gst_Reg || ''} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Suppliermst;
