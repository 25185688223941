import React, { useEffect, useState } from "react";
import { FaCheck, FaPlus } from "react-icons/fa6";
import { MdEdit } from "react-icons/md";
import { LuView } from "react-icons/lu";
import { HiOutlineRefresh } from "react-icons/hi";
import { FaSave, FaSearch } from "react-icons/fa";
import { IoPrint } from "react-icons/io5";
import { RiCloseLargeFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import A1 from '../Images/logo-w.png';
import axios from 'axios';


const ItemMaster = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ itemCd: '', itemDesc: '', hsnCode: '', taxRate: '', closeFlag: '', itc: '', image: '', itemUOM: '', itemLocation: '', origin: '', cth: '', minQty: '', Main_Grp: '', Main_Grp_Desc: '', grade: '', validity: '', maxQty: '', Sub_Grp: '', Sub_Grp_Desc: '', abc: '', ved: '', reOrderQty: '', make: '', prodCode: '', department: '', department1: '', bretonFlag: '', blockUnblock: '', accountCode: '', accountCodee: '' });
    const [showForm, setShowForm] = useState(false);
    const [items, setItems] = useState([]);
    // const [selectedItem , setViewItem] = useState({});

    const user = JSON.parse(localStorage.getItem('user'));
    const user_Id = user ? user.id : null;

    useEffect(() => {
        fetchItems();
    }, [user_Id]);


    const fetchItems = async () => {
        try {
            const response = await fetch('https://erpapi.emedha.in/pages/api/api/itemsmasterform');
            if (!response.ok) {
                throw new Error('Failed to fetch items');
            }
            const data = await response.json();
            setItems(data);
        } catch (err) {
            console.error('Error fetching items:', err.message);
        }
    };

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredItems, setFilteredItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleItemSelect = (item) => {
        setSelectedItem(item);
        setSearchTerm(item.itemCd);
        setDropdownVisible1(false)
        setFilteredItems([]);
    };

    const fetchItemss = async (searchTerm = '') => {
        try {
            const response = await fetch(`https://erpapi.emedha.in/pages/api/api/itemsmasterform?searchTerm=${searchTerm}`);
            if (!response.ok) {
                throw new Error('Failed to fetch items');
            }
            const data = await response.json();
            setFilteredItems(data);
        } catch (err) {
            console.error('Error fetching items:', err.message);
        }
    };

    useEffect(() => {
        if (searchTerm) {
            fetchItemss(searchTerm);
        } else {
            setFilteredItems([]);
        }
    }, [searchTerm]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(null);

    const handleSaveItem = async () => {

        if (!formData.itemUOM || !formData.origin || !formData.itemCd) {
            alert('ItemCd, ItemDesc, and HSNCode are required!');
            return;
        }
        const form = new FormData();

        // Update form.append with the new keys
        form.append('itemCd', formData.itemCd);
        form.append('itemDesc', formData.itemDesc);
        form.append('hsnCode', formData.hsnCode);
        form.append('taxRate', formData.taxRate);
        form.append('closeFlag', formData.closeFlag);
        form.append('itc', formData.itc);
        form.append('itemUOM', formData.itemUOM);
        form.append('itemLocation', formData.itemLocation);
        form.append('origin', formData.origin);
        form.append('cth', formData.cth);
        form.append('minQty', formData.minQty);
        form.append('Main_Grp', formData.Main_Grp);
        form.append('Main_Grp_Desc', formData.Main_Grp_Desc);
        form.append('grade', formData.grade);
        form.append('validity', formData.validity);
        form.append('maxQty', formData.maxQty);
        form.append('Sub_Grp', formData.Sub_Grp);
        form.append('Sub_Grp_Desc', formData.Sub_Grp_Desc);
        form.append('abc', formData.abc);
        form.append('ved', formData.ved);
        form.append('reOrderQty', formData.reOrderQty);
        form.append('make', formData.make);
        form.append('prodCode', formData.prodCode);
        form.append('department', formData.department);
        form.append('department1', formData.department1);
        form.append('bretonFlag', formData.bretonFlag);
        form.append('blockUnblock', formData.blockUnblock);
        form.append('accountCode', formData.accountCode);
        form.append('accountCodee', formData.accountCodee);

        // Append image if it exists
        if (formData.image) {
            form.append('image', formData.image);
        }

        try {
            if (isEditing) {
                await axios.put(`https://erpapi.emedha.in/pages/api/api/itemsss-mst/${editId}`, form);
                setItems(items.map(item => (item.id === editId ? { ...formData, id: editId } : item)));
                alert('Item updated successfully!');
            } else {
                const response = await axios.post('https://erpapi.emedha.in/pages/api/api/itemsss-mst', form, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setItems([...items, { ...formData, id: response.data.itemId }]);
                alert('Item added successfully!');
            }
        } catch (error) {
            alert('Item added successfully!');
            console.error('Error saving item:', error);
        }
    };

    const [dropdownVisible1, setDropdownVisible1] = useState(false)

    const handleEditItem = (item) => {
        setSearchTerm(item.itemCd);
        setFormData({
            itemCd: item.itemCd,
            itemDesc: item.itemDesc,
            hsnCode: item.hsnCode,
            taxRate: item.taxRate,
            closeFlag: item.closeFlag,
            itc: item.itc,
            image: item.image,
            itemUOM: item.itemUOM,
            itemLocation: item.itemLocation,
            origin: item.origin,
            cth: item.cth,
            minQty: item.minQty,
            Main_Grp: item.Main_Grp,
            Main_Grp_Desc: item.Main_Grp_Desc,
            grade: item.grade,
            validity: item.validity,
            maxQty: item.maxQty,
            Sub_Grp: item.Sub_Grp,
            Sub_Grp_Desc: item.Sub_Grp_Desc,
            abc: item.abc,
            ved: item.ved,
            reOrderQty: item.reOrderQty,
            make: item.make,
            prodCode: item.prodCode,
            department: item.department,
            department1: item.department1,
            bretonFlag: item.bretonFlag,
            blockUnblock: item.blockUnblock,
            accountCode: item.accountCode,
            accountCodee: item.accountCodee
        });
        setEditId(item.id);
        setIsEditing(true);
        setShowForm(true);
        setDropdownVisible1(false);
        setShowViewFormm(false);
    };

    const [showSearch, setShowSearch] = useState(false);
    const [showviewformm, setShowViewFormm] = useState(false);
    const handleBack = () => { navigate(-1); };

    const toggleForm = () => {
        setShowForm(prevState => !prevState);
        setFormData({ itemCd: '', itemDesc: '', hsnCode: '', taxRate: '', closeFlag: '', itc: '', image: '', itemUOM: '', itemLocation: '', origin: '', cth: '', minQty: '', Main_Grp: '', Main_Grp_Desc: '', grade: '', validity: '', maxQty: '', Sub_Grp: '', Sub_Grp_Desc: '', abc: '', ved: '', reOrderQty: '', make: '', prodCode: '', department: '', department1: '', bretonFlag: '', blockUnblock: '', accountCode: '', accountCodee: '' });
        setIsEditing(false);
        setEditId(null);
        setShowForm(true);
        setShowViewFormm(false);
        setShowSearch(false);
    };

    const handleRefresh = () => {
        setFormData({
            itemCd: '', itemDesc: '', hsnCode: '', taxRate: '', closeFlag: '', itc: '', image: '', itemUOM: '', itemLocation: '', origin: '', cth: '', minQty: '', Main_Grp: '', Main_Grp_Desc: '', grade: '', validity: '', maxQty: '', Sub_Grp: '', Sub_Grp_Desc: '', abc: '', ved: '', reOrderQty: '', make: '', prodCode: '', department: '', department1: '', bretonFlag: '', blockUnblock: '', accountCode: '', accountCodee: ''
        });
        setSelectedItem({
            itemCd: '', itemDesc: '', hsnCode: '', taxRate: '', closeFlag: '', itc: '', image: '', itemUOM: '', itemLocation: '', origin: '', cth: '', minQty: '', Main_Grp: '', Main_Grp_Desc: '', grade: '', validity: '', maxQty: '', Sub_Grp: '', Sub_Grp_Desc: '', abc: '', ved: '', reOrderQty: '', make: '', prodCode: '', department: '', department1: '', bretonFlag: '', blockUnblock: '', accountCode: '', accountCodee: ''
        });
    };

    const handleViewItem = (item) => {
        setSelectedItem(item);
        setShowViewFormm(true);
        setShowForm(false);
        setDropdownVisible1(false);
    };

    const userPermissions = user ? {
        add: user.add_permission,
        edit: user.edit_permission,
        view: user.view_permission,
        save: user.save_permission,
        approve: user.approve_permission,
        print: user.print_permission,
        close: user.close_permission,
        clear: user.clear_screen_permission
    } : {};

    console.log(userPermissions)

    const handleApprove = (id) => {
        if (id) {
            axios
                .put(`https://erpapi.emedha.in/pages/api/api/itemsss-mst/approve/${id}`)
                .then((response) => {
                    console.log('Item approved successfully:', response.data);
                    setItems((prevItems) =>
                        prevItems.map((item) =>
                            item.id === id ? { ...item, isApproved: true } : item
                        )
                    );
                    alert('Item approved successfully');
                })
                .catch((error) => {
                    console.error('Error approving item:', error);
                    alert('Failed to approve item');
                });
        } else {
            alert("Item ID is invalid.");
        }
    };

    const handlePrint = () => {
        const printContent = document.getElementById("print-content");
        if (printContent) {
            const originalContent = document.body.innerHTML;
            document.body.innerHTML = printContent.innerHTML;
            window.print();
            document.body.innerHTML = originalContent;
        } else {
            alert("Printer content not found!");
        }
    };

    return (
        <>
            <div style={{ backgroundColor: "#dbc4a9", padding: "1rem", margin: "0 auto", padding: "1rem", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }} >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <img src={A1} alt="item" style={{ width: "20%" }} />
                    <h1 style={{ color: "darkblue", fontWeight: 'bold', fontSize: "1.95rem", fontWeight: "1000", marginBottom: "1rem" }}>
                        ITEM MASTER
                    </h1>
                    <div style={{ display: "flex", gap: "1rem", cursor: 'pointer' }}>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.add && userPermissions.add !== 0 ? "pointer" : "not-allowed", opacity: userPermissions.add && userPermissions.add !== 0 ? 1 : 0.5 }}>
                            <FaPlus size={30} onClick={() => { if (userPermissions.add && userPermissions.add !== 0) { toggleForm(); setShowSearch(false); } }} />
                        </div>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.edit && userPermissions.edit !== 0 ? "pointer" : "not-allowed", opacity: userPermissions.edit && userPermissions.edit !== 0 ? 1 : 0.5 }}>
                            <MdEdit size={30} onClick={() => {
                                if (userPermissions.edit && userPermissions.edit !== 0) {
                                    setShowForm(true); setShowSearch(true);
                                    if (selectedItem) {
                                        setFormData({
                                            itemCd: selectedItem.itemCd, itemDesc: selectedItem.itemDesc, hsnCode: selectedItem.hsnCode, taxRate: selectedItem.taxRate, closeFlag: selectedItem.closeFlag, itc: selectedItem.itc, image: selectedItem.image, itemUOM: selectedItem.itemUOM, itemLocation: selectedItem.itemLocation, origin: selectedItem.origin, cth: selectedItem.cth, minQty: selectedItem.minQty, Main_Grp: selectedItem.Main_Grp, Main_Grp_Desc: selectedItem.Main_Grp_Desc, grade: selectedItem.grade, validity: selectedItem.validity, maxQty: selectedItem.maxQty, Sub_Grp: selectedItem.Sub_Grp, Sub_Grp_Desc: selectedItem.Sub_Grp_Desc, abc: selectedItem.abc, ved: selectedItem.ved, reOrderQty: selectedItem.reOrderQty, make: selectedItem.make, prodCode: selectedItem.prodCode, department: selectedItem.department, department1: selectedItem.department1, bretonFlag: selectedItem.bretonFlag, blockUnblock: selectedItem.blockUnblock, accountCode: selectedItem.accountCode, accountCodee: selectedItem.accountCodee,
                                        });
                                    } else {
                                        setFormData({ itemCd: '', itemDesc: '', hsnCode: '', taxRate: '', closeFlag: '', itc: '', image: '', itemUOM: '', itemLocation: '', origin: '', cth: '', minQty: '', Main_Grp: '', Main_Grp_Desc: '', grade: '', validity: '', maxQty: '', Sub_Grp: '', Sub_Grp_Desc: '', abc: '', ved: '', reOrderQty: '', make: '', prodCode: '', department: '', department1: '', blockUnblock: '', accountCode: '', accountCodee: '' });
                                    }
                                } else { alert("You do not have permission to edit."); }
                            }} />
                        </div>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.view && userPermissions.view !== 0 ? "pointer" : "not-allowed", opacity: userPermissions.view && userPermissions.view !== 0 ? 1 : 0.5 }}>
                            <LuView size={30} onClick={userPermissions.view && userPermissions.view !== 0 ? handleViewItem : null} />
                        </div>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.clear && userPermissions.clear !== 0 ? "pointer" : "not-allowed", opacity: userPermissions.clear && userPermissions.clear !== 0 ? 1 : 0.5 }}>
                            <HiOutlineRefresh size={30} onClick={userPermissions.clear && userPermissions.clear !== 0 ? handleRefresh : null} />
                        </div>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.save && userPermissions.save !== 0 ? "pointer" : "not-allowed", opacity: userPermissions.save && userPermissions.save !== 0 ? 1 : 0.5 }}>
                            <FaSave size={30} onClick={userPermissions.save && userPermissions.save !== 0 ? handleSaveItem : null} />
                        </div>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.approve && userPermissions.approve !== 0 ? "pointer" : "not-allowed", opacity: userPermissions.approve && userPermissions.approve !== 0 ? 1 : 0.5 }}>
                            <FaCheck size={30} onClick={userPermissions.approve && userPermissions.approve !== 0 ? () => handleApprove(selectedItem.id) : null} />
                        </div>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.print && userPermissions.print !== 0 ? "pointer" : "not-allowed", opacity: userPermissions.print && userPermissions.print !== 0 ? 1 : 0.5 }}>
                            <IoPrint size={30} onClick={userPermissions.print && userPermissions.print !== 0 ? handlePrint : null} />
                        </div>
                        <div style={{ width: "40px", height: "40px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: userPermissions.close && userPermissions.close !== 0 ? "pointer" : "not-allowed", opacity: userPermissions.close && userPermissions.close !== 0 ? 1 : 0.5 }}>
                            <RiCloseLargeFill size={30} onClick={userPermissions.close && userPermissions.close !== 0 ? handleBack : null} />
                        </div>
                    </div>
                </div>
                <hr />
                {showForm && (
                    <>
                        <div className="container">
                            {showSearch && (
                                <div className="mb-3" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '30%', position: 'relative', paddingLeft: '46px' }}>
                                    <label className="me-4 mb-2" style={{ color: "darkblue", fontWeight: 'bold' }}><FaSearch /></label>
                                    <input type="text" placeholder="Search by Edit Item CD" value={searchTerm}
                                        onChange={(e) => { setSearchTerm(e.target.value); setDropdownVisible1(true); }}
                                        style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} />
                                    {filteredItems.length > 0 && dropdownVisible1 && (
                                        <ul style={{ maxHeight: "150px", overflowY: "auto", border: "1px solid #e2e8f0", width: "100%", position: "absolute", backgroundColor: "#fff", zIndex: 1, marginTop: '5px', padding: 0, top: '100%' }}>
                                            {filteredItems.map((item) => (
                                                <li
                                                    key={item.id}
                                                    onClick={() => handleEditItem(item)}
                                                    style={{ padding: "0.5rem", cursor: "pointer", listStyleType: "none", borderBottom: "1px solid #e2e8f0" }}>
                                                    {item.itemCd}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            )}
                            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', width: '100%' }}>
                                {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '30%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>ItemCd</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="itemCd" value={formData.itemCd || ''} onChange={handleInputChange} />
                                </div> */}
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '60%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>ItemDesc</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="itemDesc" value={formData.itemDesc || ''} onChange={handleInputChange} />
                                </div>
                                <div style={{ width: '20%' }}>
                                    <button style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem", backgroundColor: "#edf2f7" }}>
                                        Barcode Print
                                    </button>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '15%' }}>
                                    <input type="file" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="image" onChange={(e) => setFormData({ ...formData, image: e.target.files[0] })} />
                                </div>
                            </div>

                            <div className="mt-3" style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '35%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>HSNCode</label>
                                    <input type="number" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="hsnCode" value={formData.hsnCode || ''} onChange={handleInputChange} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '20%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>TaxRate</label>
                                    <input type="number" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="taxRate" value={formData.taxRate || ''} onChange={handleInputChange} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '10%', paddingLeft: '200px' }}>
                                    <input type="checkbox" style={{ width: "30px", height: "15px", border: "1px solid #e2e8f0", padding: "0.25rem", backgroundColor: "#edf2f7" }} name="closeFlag" value={formData.closeFlag || ''} onChange={handleInputChange} />
                                    <label style={{ color: "darkblue", fontWeight: 'bold', marginLeft: '0.5rem' }}>CloseFlag</label>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '30%', paddingLeft: '16px' }}>
                                <label className="me-5" style={{ color: "darkblue", fontWeight: 'bold' }}>ITC</label>
                                <select style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="itc" value={formData.itc} onChange={handleInputChange}>
                                    <option value="Select Option">Select Option</option>
                                    <option value="ITC1">ITC1</option>
                                    <option value="ITC2">ITC2</option>
                                </select>
                            </div>
                        </div>
                        <div className="mt-2 me-5" style={{ marginBottom: "1rem" }}>
                            <label style={{ display: "block", color: "darkblue", fontWeight: 'bold', }}>ITEM CHARACTERSTICS</label>
                        </div>
                        <div style={{ border: "1px solid #e2e8f0", padding: "1rem" }}>
                            <div className="mt-3" style={{ display: 'flex', alignItems: 'center', gap: '1rem', width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '30%' }}>
                                    <label className="me-4" style={{ color: "darkblue", fontWeight: 'bold' }}>ITEMUOM</label>
                                    <select style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="itemUOM" value={formData.itemUOM} onChange={handleInputChange} required >
                                        <option value="Select Option">Select Option</option>
                                        <option value="KG">KG</option>
                                        <option value="TON">TON</option>
                                        <option value="LTRS">LTRS</option>
                                        <option value="PCS">PCS</option>
                                    </select>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '62%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>ItemLocation</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="itemLocation" value={formData.itemLocation} onChange={handleInputChange} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '24%' }}>
                                    <label className="me-4" style={{ color: "darkblue", fontWeight: 'bold' }}>ORGIN</label>
                                    <select style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="origin" value={formData.origin} onChange={handleInputChange} >
                                        <option value="Select Option">Select Option</option>
                                        <option>Local</option>
                                        <option>international</option>
                                    </select>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '25%' }}>
                                    <label className="me-4" style={{ color: "darkblue", fontWeight: 'bold' }}>CTH</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="cth" value={formData.cth} onChange={handleInputChange} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '25%' }}>
                                    <label className="me-5" style={{ color: "darkblue", fontWeight: 'bold' }}>MinQty</label>
                                    <input type="number" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="minQty" value={formData.minQty} onChange={handleInputChange} />
                                </div>
                            </div>

                            <div className="mt-3" style={{ display: 'flex', alignItems: 'center', gap: '1rem', width: '100%' }}>

                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '30%', paddingLeft: '9px' }}>
                                    <label className="me-5" style={{ color: "darkblue", fontWeight: 'bold' }}>MAKE</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="make" value={formData.make} onChange={handleInputChange} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '60%', paddingLeft: '21px' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>ProdCode</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="prodCode" value={formData.prodCode} onChange={handleInputChange} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '24%' }}>
                                    <label className="me-4" style={{ color: "darkblue", fontWeight: 'bold' }}>GRADE</label>
                                    <select style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="grade" value={formData.grade} onChange={handleInputChange} required>
                                        <option value="Select Option">Select Option</option>
                                        <option>GRADE A</option>
                                        <option>GRADE B</option>
                                        <option>GRADE C</option>
                                        <option>GRADE D</option>
                                    </select>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '25%' }}>
                                    <label style={{ color: "darkblue", fontWeight: 'bold' }}>Validity</label>
                                    <select style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="validity" value={formData.validity} onChange={handleInputChange} >
                                        <option value="Select Option">Select Option</option>
                                        <option value="1Month">1Month</option>
                                        <option value="2months">2months</option>
                                    </select>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '25%' }}>
                                    <label className="me-5" style={{ color: "darkblue", fontWeight: 'bold' }}>MaxQty</label>
                                    <input type="number" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="maxQty" value={formData.maxQty} onChange={handleInputChange} />
                                </div>
                            </div>

                            <div className="mt-3" style={{ display: 'flex', alignItems: 'center', gap: '1rem', width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '32%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>MainGroup</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="Main_Grp" value={formData.Main_Grp} onChange={handleInputChange} />
                                </div>
                                <input type="text" style={{ width: "60%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="Main_Grp_Desc" value={formData.Main_Grp_Desc} onChange={handleInputChange} />
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '24%' }}>
                                    <label className="me-5" style={{ color: "darkblue", fontWeight: 'bold' }}>ABC</label>
                                    <select style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="abc" value={formData.abc} onChange={handleInputChange} >
                                        <option value="Select Option">Select Option</option>
                                        <option value="ABC1">ABC1</option>
                                        <option value="ABC2">ABC2</option>
                                    </select>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '25%' }}>
                                    <label className="me-4" style={{ color: "darkblue", fontWeight: 'bold' }}>VED</label>
                                    <select style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="ved" value={formData.ved} onChange={handleInputChange} >
                                        <option value="Select Option">Select Option</option>
                                        <option value="VED1">VED1</option>
                                        <option value="VED2">VED2</option>
                                    </select>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '25%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>ReOrderQty</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="reOrderQty" value={formData.reOrderQty} onChange={handleInputChange} />
                                </div>
                            </div>


                            <div className="mt-3 mb-3" style={{ display: 'flex', alignItems: 'center', gap: '1rem', width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '33%' }}>
                                    <label className="me-4" style={{ color: "darkblue", fontWeight: 'bold' }}>SubGroup</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="Sub_Grp" value={formData.Sub_Grp} onChange={handleInputChange} />
                                </div>
                                <input type="text" style={{ width: "64%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="Sub_Grp_Desc" value={formData.Sub_Grp_Desc} onChange={handleInputChange} />
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '30%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>Department</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="department" value={formData.department} onChange={handleInputChange} />
                                </div>
                                <input type="text" style={{ width: "50%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="department1" value={formData.department1} onChange={handleInputChange} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <div style={{ flexGrow: 1, borderTop: '1px solid black', marginRight: '10px', }}></div>
                                <span style={{ padding: '0 10px', fontSize: '25px' }}>Accounts</span>
                                <div style={{ flexGrow: 1, borderTop: '1px solid black', marginLeft: '10px', }}></div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '30%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>Block / Unblock</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="blockUnblock" value={formData.blockUnblock || ''} onChange={handleInputChange} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '60%', paddingLeft: '500px' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>A/c Code</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="accountCode" value={formData.accountCode} onChange={handleInputChange} />
                                </div>
                                <input type="text" style={{ width: "40%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="accountCodee" value={formData.accountCodee} onChange={handleInputChange} />
                            </div>
                        </div>
                    </>
                )}
            </div>

            {showviewformm && !showForm && selectedItem && (
                <>
                    <div style={{ backgroundColor: "#dbc4a9" }} >
                        <div className="container">


                            <div style={{ display: 'flex', alignItems: 'flex-start', width: '25%', position: 'relative',paddingLeft:'50px' }}>
                                <label className="me-4" style={{ color: "darkblue", fontWeight: 'bold' }}><FaSearch /></label>
                                <input type="text" placeholder="Search by Item CD" value={searchTerm}
                                    onChange={(e) => { setSearchTerm(e.target.value); setDropdownVisible1(true); }}
                                    style={{ padding: "0.25rem", border: "1px solid #e2e8f0", width: '100%' }} />
                                {filteredItems.length > 0 && dropdownVisible1 && (
                                    <ul style={{ maxHeight: "150px", overflowY: "auto", border: "1px solid #e2e8f0", width: "100%", position: "absolute", backgroundColor: "#fff", zIndex: 1, marginTop: '5px', padding: 0, top: '100%' }}>
                                        {filteredItems.map((item) => (
                                            <li key={item.id}
                                                onClick={() => handleItemSelect(item)}
                                                style={{ padding: "0.5rem", cursor: "pointer", listStyleType: "none", borderBottom: "1px solid #e2e8f0" }}>
                                                {item.itemCd}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '80%',paddingLeft:'2px' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>ItemDesc</label>
                                    <input type="text" style={{ width: "80%", border: "1px solid #e2e8f0", padding: "0.25rem" }} value={selectedItem.itemDesc || ''} />
                                </div>
                                <div style={{ width: '20%' }}>
                                    <button style={{ width: "60%", border: "1px solid #e2e8f0", padding: "0.25rem", backgroundColor: "#edf2f7" }}>
                                        BarcodePrint
                                    </button>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '15%',paddingLeft:'100px' }}>
                                    <img src={selectedItem.image || ''} alt="item" style={{ width: "100px", height: "100px", objectFit: "cover", marginTop: "10px" }} />
                                </div>
                            </div>
                            <div style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '35%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>HSNCode</label>
                                    <input type="number" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="hsnCode" value={selectedItem.hsnCode || ''} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '20%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>TaxRate%</label>
                                    <input type="number" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="taxRate" value={selectedItem.taxRate || ''} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '10%', paddingLeft: '200px' }}>
                                    <input type="checkbox" style={{ width: "30px", height: "15px", border: "1px solid #e2e8f0", padding: "0.25rem", backgroundColor: "#edf2f7" }} name="closeFlag" value={selectedItem.closeFlag || ''} />
                                    <label style={{ color: "darkblue", fontWeight: 'bold', marginLeft: '0.5rem' }}>CloseFlag</label>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '30%',paddingLeft:'17px' }}>
                                <label className="me-5" style={{ color: "darkblue", fontWeight: 'bold' }}>ITC</label>
                                <select style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} value={selectedItem.itc || ''} >
                                    <option value="Select Option">Select Option</option>
                                    <option value="ITC1">ITC1</option>
                                    <option value="ITC2">ITC2</option>
                                </select>
                            </div>
                        </div>

                        <div className="mt-2 mb-1" style={{ marginBottom: "1rem" }}>
                            <label style={{ display: "block", color: "darkblue", fontWeight: 'bold', }}>ITEM CHARACTERSTICS</label>
                        </div>

                        <div style={{ border: "1px solid #e2e8f0", padding: "1rem" }}>
                            <div className="mt-3" style={{ display: 'flex', alignItems: 'center', gap: '1rem', width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '30%' }}>
                                    <label className="me-4" style={{ color: "darkblue", fontWeight: 'bold' }}>ITEMUOM</label>
                                    <select style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="itemUOM" value={selectedItem.itemUOM || ''} required >
                                        <option value="Select Option">Select Option</option>
                                        <option value="KG">KG</option>
                                        <option value="TON">TON</option>
                                        <option value="LTRS">LTRS</option>
                                        <option value="PCS">PCS</option>
                                    </select>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '62%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>ItemLocation</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="itemLocation" value={selectedItem.itemLocation || ''} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '24%' }}>
                                    <label className="me-4" style={{ color: "darkblue", fontWeight: 'bold' }}>ORGIN</label>
                                    <select style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="origin" value={selectedItem.origin || ''}  >
                                        <option value="Select Option">Select Option</option>
                                        <option>Local</option>
                                        <option>international</option>
                                    </select>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '25%' }}>
                                    <label className="me-4" style={{ color: "darkblue", fontWeight: 'bold' }}>CTH</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="cth" value={selectedItem.cth || ''} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '25%' }}>
                                    <label className="me-5" style={{ color: "darkblue", fontWeight: 'bold' }}>MinQty</label>
                                    <input type="number" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="minQty" value={selectedItem.minQty || ''} />
                                </div>
                            </div>

                            <div className="mt-3" style={{ display: 'flex', alignItems: 'center', gap: '1rem', width: '100%' }}>

                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '30%', paddingLeft: '9px' }}>
                                    <label className="me-5" style={{ color: "darkblue", fontWeight: 'bold' }}>MAKE</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="make" value={selectedItem.make || ''} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '60%', paddingLeft: '21px' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>ProdCode</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="prodCode" value={selectedItem.prodCode || ''} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '24%' }}>
                                    <label className="me-4" style={{ color: "darkblue", fontWeight: 'bold' }}>GRADE</label>
                                    <select style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="grade" value={selectedItem.grade || ''} required>
                                        <option value="Select Option">Select Option</option>
                                        <option>GRADE A</option>
                                        <option>GRADE B</option>
                                        <option>GRADE C</option>
                                        <option>GRADE D</option>
                                    </select>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '25%' }}>
                                    <label style={{ color: "darkblue", fontWeight: 'bold' }}>Validity</label>
                                    <select style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="validity" value={selectedItem.validity || ''}  >
                                        <option value="Select Option">Select Option</option>
                                        <option value="1Month">1Month</option>
                                        <option value="2months">2months</option>
                                    </select>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '25%' }}>
                                    <label className="me-5" style={{ color: "darkblue", fontWeight: 'bold' }}>MaxQty</label>
                                    <input type="number" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="maxQty" value={selectedItem.maxQty || ''} />
                                </div>
                            </div>

                            <div className="mt-3" style={{ display: 'flex', alignItems: 'center', gap: '1rem', width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '32%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>MainGroup</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="Main_Grp" value={selectedItem.Main_Grp || ''} />
                                </div>
                                <input type="text" style={{ width: "60%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="Main_Grp_Desc" value={selectedItem.Main_Grp_Desc || ''} />
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '24%' }}>
                                    <label className="me-5" style={{ color: "darkblue", fontWeight: 'bold' }}>ABC</label>
                                    <select style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="abc" value={selectedItem.abc || ''}  >
                                        <option value="Select Option">Select Option</option>
                                        <option value="ABC1">ABC1</option>
                                        <option value="ABC2">ABC2</option>
                                    </select>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '25%' }}>
                                    <label className="me-4" style={{ color: "darkblue", fontWeight: 'bold' }}>VED</label>
                                    <select style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="ved" value={selectedItem.ved || ''}  >
                                        <option value="Select Option">Select Option</option>
                                        <option value="VED1">VED1</option>
                                        <option value="VED2">VED2</option>
                                    </select>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '25%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>ReOrderQty</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="reOrderQty" value={selectedItem.reOrderQty || ''} />
                                </div>
                            </div>


                            <div className="mt-3 mb-3" style={{ display: 'flex', alignItems: 'center', gap: '1rem', width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '33%' }}>
                                    <label className="me-4" style={{ color: "darkblue", fontWeight: 'bold' }}>SubGroup</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="Sub_Grp" value={selectedItem.Sub_Grp || ''} />
                                </div>
                                <input type="text" style={{ width: "64%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="Sub_Grp_Desc" value={selectedItem.Sub_Grp_Desc || ''} />
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '30%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>Department</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="department" value={selectedItem.department || ''} />
                                </div>
                                <input type="text" style={{ width: "50%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="department1" value={selectedItem.department1 || ''} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <div style={{ flexGrow: 1, borderTop: '1px solid black', marginRight: '10px', }}></div>
                                <span style={{ padding: '0 10px', fontSize: '25px' }}>Accounts</span>
                                <div style={{ flexGrow: 1, borderTop: '1px solid black', marginLeft: '10px', }}></div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '30%' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>Block / Unblock</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="blockUnblock" value={selectedItem.blockUnblock || ''} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '60%', paddingLeft: '500px' }}>
                                    <label className="me-3" style={{ color: "darkblue", fontWeight: 'bold' }}>A/c Code</label>
                                    <input type="text" style={{ width: "100%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="accountCode" value={selectedItem.accountCode || ''} />
                                </div>
                                <input type="text" style={{ width: "40%", border: "1px solid #e2e8f0", padding: "0.25rem" }} name="accountCodee" value={selectedItem.accountCodee || ''} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default ItemMaster;
